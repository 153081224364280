import { isMobile } from "react-device-detect";

// Enum for browser types
export enum BrowserType {
  MicrosoftEdge = "Microsoft Edge",
  GoogleChrome = "Google Chrome",
  Safari = "Safari",
  MozillaFirefox = "Mozilla Firefox",
  MobileSafari = "Mobile Safari",
  MobileGoogleChrome = "Mobile Google Chrome",
  MobileGeneric = "Generic Mobile Browser",
  Generic = "Generic Browser",
}

export function getBrowserType(): BrowserType {
  const userAgent: string = navigator.userAgent;

  if (userAgent.includes("Mobile")) {
    // Safari (mobile)
    if (userAgent.includes("Safari") && userAgent.includes("Version")) {
      return BrowserType.MobileSafari;

      // Google Chrome (mobile)
    } else if (userAgent.includes("CriOS") || userAgent.includes("Android")) {
      return BrowserType.MobileGoogleChrome;

      // Fallback for unknown browsers
    } else {
      return BrowserType.MobileGeneric;
    }
  } else {
    // Microsoft Edge (Chromium-based)
    if (userAgent.includes("Edg")) {
      return BrowserType.MicrosoftEdge;

      // Google Chrome (exclude Edge, Opera, and Chromium-based WebView)
    } else if (userAgent.includes("Chrome") && !userAgent.includes("Edg") && !userAgent.includes("OPR")) {
      return BrowserType.GoogleChrome;

      // Safari (desktop, excludes Chrome-based browsers)
    } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
      return BrowserType.Safari;

      // Mozilla Firefox
    } else if (userAgent.includes("Firefox") || userAgent.includes("FxiOS")) {
      return BrowserType.MozillaFirefox;
    } else {
      return BrowserType.Generic;
    }
  }
}

export function getBrowserCameraHintUrl(): string {
  const browser = getBrowserType();
  let url = "https://docs.intelliprove.com/users/enabling-camera-access-for-the-intelliprove-plug-in";

  const urls: Record<BrowserType, string> = {
    "Google Chrome": "https://docs.intelliprove.com/users/enabling-camera-access-for-the-intelliprove-plug-in#7i6uo",
    "Mozilla Firefox": "https://docs.intelliprove.com/users/enabling-camera-access-for-the-intelliprove-plug-in#H6Dxk",
    Safari: "https://docs.intelliprove.com/users/enabling-camera-access-for-the-intelliprove-plug-in#AbJSP",
    "Microsoft Edge": "https://docs.intelliprove.com/users/enabling-camera-access-for-the-intelliprove-plug-in#CGn52",

    "Mobile Safari": "https://docs.intelliprove.com/users/enabling-camera-access-for-the-intelliprove-plug-in#1szRH",
    "Mobile Google Chrome": "https://docs.intelliprove.com/users/enabling-camera-access-for-the-intelliprove-plug-in#ltqRS",

    "Generic Mobile Browser": "https://docs.intelliprove.com/users/enabling-camera-access-for-the-intelliprove-plug-in#mT9P2",
    "Generic Browser": url,
  };

  if (browser in urls) {
    url = urls[browser];
  }

  if (browser == BrowserType.Generic && isMobile) url = urls[BrowserType.MobileGeneric];

  return url;
}
