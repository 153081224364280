export const getExtension = (path: string): string => "." + path.split(".").pop();

export const getAvailableCameras = async (): Promise<Array<MediaDeviceInfo>> => {
  const all = await navigator.mediaDevices.enumerateDevices();
  return all.filter((m) => m.kind === "videoinput");
};

export const userHasCamera = async (): Promise<boolean> => {
  try {
    return (await getAvailableCameras()).length > 0;
  } catch {
    return false;
  }
};
