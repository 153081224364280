import { Route, createRoutesFromElements, RouterProvider, createHashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { useEffect } from "react";
import packageInfo from "../package.json";

import "@/translations/i18next";
import { Splashscreen } from "./pages/Splashscreen/Splashscreen";
import { WelcomePage } from "./pages/Welcome/Welcome";
import { MeasurementPage } from "./pages/Measurement/Measurement";
import { OnBoardingPage } from "./pages/Onboarding/Onboarding";
import store from "./store/store";
import { ResultPage } from "./pages/Result/Result";
import { NotFound } from "./pages/NotFound/NotFound";
import { WindowContextProvider } from "./providers/WindowContext.provider";
import { LanguageProvider, useLanguage } from "./context/language";
import i18n from "@/translations/i18next";
import { AppBootstrap } from "./AppBootstrap";
import { ThemeProvider } from "./context/theme";
import { TrendsPage } from "./pages/Trends/Trends";

const router = createHashRouter(
  createRoutesFromElements(
    <Route element={<AppBootstrap />} errorElement={<NotFound />}>
      <Route path="" element={<Splashscreen />} />
      <Route path="/welcome" element={<WelcomePage />} />
      <Route path="/measurement">
        <Route path="do" element={<MeasurementPage />} />
        <Route path="onboarding" element={<OnBoardingPage />} />
        <Route path="result/:uuid" element={<ResultPage />} />
        <Route path="history/:uuid" element={<TrendsPage />} />
      </Route>
    </Route>,
  ),
);

const VersionWrapper = () => {
  const version = packageInfo.version;
  return <div className="fixed bottom-2 right-4 text-[10px] font-light text-black dark:text-white">v{version}</div>;
};

const ProviderApp = () => {
  const { language } = useLanguage();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <WindowContextProvider>
          <RouterProvider router={router} />
        </WindowContextProvider>
      </Provider>
    </I18nextProvider>
  );
};

function App() {
  return (
    <LanguageProvider>
      <ThemeProvider>
        <ProviderApp />
        <VersionWrapper />
      </ThemeProvider>
    </LanguageProvider>
  );
}

export default App;
